<!--
 * @Description: 
 * @Author: weig
 * @Date: 2021-05-24 10:24:20
 * @LastEditors: weig
 * @LastEditTime: 2022-02-10 17:26:09
-->
<template>
    <div>
        <!-- 面包屑 begin -->
        <eup-crumbs icon="el-icon-truck" firstCrumbs="建议反馈管理" secondCrumbs="建议反馈列表" />
        <!-- 面包屑 end -->

        <!-- 内容区域 begin -->
        <div class="container">
            <div class="handle-box">
              <el-form  :model="state.query" :inline="true" v-if="checkPermission([`api${state.VIEW_VERSION}:system:feedback:get`],[`api${state.VIEW_VERSION}:system:feedback:batchdelete`],[`api${state.VIEW_VERSION}:system:feedback:getpage`])">
                 <template v-if="checkPermission([`api${state.VIEW_VERSION}:system:feedback:get`])">
                <el-form-item label="反馈类型">
                  <el-select v-model="state.query.feedbackType" placeholder="反馈类型" class="handle-select mr10" @change="handleSearch">
                    <el-option v-for="item in state.GetFeedbackTypeSelect" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="状态">
                  <el-select v-model="state.query.feedbackStatus" placeholder="状态" class="handle-select mr10" @change="handleSearch">
                    <el-option v-for="item in state.GetFeedbackStatusSelect" :key="item.value" :label="item.label" :value="item.value" ></el-option>
                  </el-select>
                </el-form-item>
                 </template>
                <el-form-item>
                  <el-button type="primary" icon="Search" @click="handleSearch">搜索</el-button>
                  <el-button
                  type="danger"
                  icon="Delete"
                  class="handle-del mr10"
                  @click="handleClickBatchDelete"
                  v-if="checkPermission([`api${state.VIEW_VERSION}:system:feedback:batchdelete`])"
                  >批量删除
              </el-button>
              </el-form-item>
              </el-form>
            </div>
            <el-table
                :data="state.tableData"
                border
                highlight-current-row
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange"
                v-loading="state.loading"
                >
                <el-table-column type="selection" width="60" align="center"></el-table-column>
                <el-table-column prop="nickName" label="用户昵称"  min-width="120" align="center"></el-table-column>
                <el-table-column prop="phone" label="手机号"  min-width="100" align="center" :formatter="phoneEmpty"></el-table-column>
                <el-table-column prop="email" label="邮箱"  min-width="130" align="center" :formatter="emailEmpty"></el-table-column>
                 <el-table-column prop="feedbackType" label="反馈类型" min-width="80" align="center">
                    <template #default="{row}">
                        {{ row.feedbackType==1 ? '建议' : '问题' }}
                  </template>
                </el-table-column>
                <el-table-column prop="feedbackStatus" label="状态" min-width="80" align="center">
                    <template #default="{row}">
                    <el-tag
                      :type="row.feedbackStatus==1 ? 'danger' : 'success'"
                      disable-transitions
                    >{{ row.feedbackStatus==1 ? '未处理' : '已处理' }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="createDate" label="提出时间"  min-width="140" align="center"></el-table-column>
                <el-table-column label="操作" min-width="180" align="center" fixed="right" v-if="checkPermission([`api${state.VIEW_VERSION}:system:feedback:update`,`api${state.VIEW_VERSION}:system:feedback:get`,`api${state.VIEW_VERSION}:system:feedback:delete`])">
                  <template #default="scope">
                      <el-button 
                          type="text"
                          icon="Edit"
                          v-show="isShow(scope.row)"
                          @click="handleClickEdit(scope.row)"
                          v-if="checkPermission([`api${state.VIEW_VERSION}:system:feedback:update`])"
                      >处理</el-button>
                      <el-button
                          type="text"
                          icon="Check"
                          @click="handleClickDetails(scope.row)"
                          v-if="checkPermission([`api${state.VIEW_VERSION}:system:feedback:get`])"
                      >查看详情</el-button>
                       <el-button
                        type="text"
                        icon="Delete"
                        class="red"
                        @click="handleClickDelete(scope.row)"
                        v-if="checkPermission([`api${state.VIEW_VERSION}:system:feedback:delete`])"
                    >删除</el-button>
                  </template>
                </el-table-column>
            </el-table>
            <!-- 分页 begin -->
            <EupPagination
                :current-page="state.pageIndex"
                :pagesizes="[10,20,50,100]"
                :pagesize="state.pageSize"
                :total="state.pageTotal"
                @getPageData="datasource"
                @resPageData="resPageData">
            </EupPagination>
            <!-- 分页 end -->
        </div>
        <!-- 内容区域 end -->

        <!--详情 -->
      <el-dialog 
          title="建议反馈详情信息" 
          :close-on-click-modal="false"
           width="60%"
          v-model="state.detailsVisible">
           <div class="home-container">
      <el-form ref="form" :model="state.form" label-width="100px">
        <el-form-item label="用户昵称">
          <el-input
            v-model="state.form.nickName"
             autocomplete="off"
             :disabled="true"
          />
        </el-form-item> 
        <el-form-item label="手机号">
          <el-input
            v-model="state.form.phone"
            placeholder="未绑定"
             autocomplete="off"
            :disabled="true"
          />
        </el-form-item>
         <el-form-item label="邮箱">
          <el-input
            v-model="state.form.email"
             placeholder="未绑定"
             autocomplete="off"
             :disabled="true"
          />
        </el-form-item> 
     <el-form-item label="反馈类型">
           <el-select v-model="state.form.feedbackType" disabled>
                  <el-option
                   v-for="item in state.FeedbackTypeSelect"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value"
                      >
                    </el-option>
                     </el-select>
    </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="state.form.feedbackStatus" disabled>
                  <el-option
                   v-for="item in state.FeedbackStatusSelect"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value"
                      >
                    </el-option>
                     </el-select>
        </el-form-item>
        <el-form-item label="提出时间">
          <el-input
            v-model="state.form.createDate"
             autocomplete="off"
             :disabled="true"
          />
        </el-form-item> 
        <el-row>
             <div v-for="(itme,index) in state.form.picture" :key="index">
         <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">        
        <el-form-item>
          <dt>
        <el-image
        style="width:150px; height:150px"
       :src="state.form.picture[index]"
       :preview-src-list="srcList"
       :initial-index="4"
      >
     </el-image>
     </dt>
        </el-form-item>
         </el-col>
        </div>
         </el-row>
         <el-form-item label="描述">
          <el-input
            v-model="state.form.description"
             autocomplete="off"
            :disabled="true"
            :autosize="{ minRows: 2, maxRows: 4 }"
            type="textarea"
          />
        </el-form-item>
      </el-form>
      </div>
       <template #footer>
        <span class="dialog-footer">
          <el-button @click="closedetailsForm()">取 消</el-button>
        </span>
      </template>
    </el-dialog>
     <!--编辑 -->
      <el-dialog 
          title="建议反馈详情信息" 
          :close-on-click-modal="false"
           width="60%"
           @close="closeEditForm()"
          v-model="state.editVisible">
           <div class="home-container">
      <el-form ref="form" :model="state.editform" label-width="100px">
     <el-form-item label="反馈类型">
           <el-select v-model="state.editform.feedbackType" disabled>
                  <el-option
                   v-for="item in state.FeedbackTypeSelect"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value"
                      >
                    </el-option>
                     </el-select>
    </el-form-item>
        <el-form-item label="状态">
           <el-select v-model="state.editform.feedbackStatus">
                  <el-option
                   v-for="item in state.FeedbackStatusSelect"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value"
                      >
                    </el-option>
                     </el-select>
        </el-form-item>
        <el-form-item label="提出时间" >
          <el-input
            v-model="state.editform.createDate"
             autocomplete="off"
            :readonly="true"
          />
        </el-form-item>
        <el-row>
             <div v-for="(itme,index) in state.editform.picture" :key="index">
         <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">        
        <el-form-item>
          <dt>
        <el-image
        style="width: 150px; height: 150px"
       :src="state.editform.picture[index]"
       :preview-src-list="srcList"
       :initial-index="1"
      >
     </el-image>
     </dt>
        </el-form-item>
         </el-col>
        </div>
         </el-row>
         <el-form-item label="描述">
          <el-input
            v-model="state.editform.description"
             autocomplete="off"
           :readonly="true"
            :autosize="{ minRows: 2, maxRows: 4 }"
            type="textarea"
          />
        </el-form-item>
      </el-form>
      </div>
       <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeEditForm()">取 消</el-button>
          <el-button type="primary" @click="DialogFormSave()">确 定</el-button>
        </span>
      </template>
           </el-dialog>
    </div>
</template>
<script>
import {reactive,onMounted} from "vue"
import { ElMessage,ElMessageBox } from 'element-plus'
import {Update,GetFeedback,GetPageFeedback,Delete,BatchDelete} from "@/serviceApi/system/Feedback";
import {elConfirmDialog} from "@/common/js/comm"
import EupPagination from "../../../components/EupPagination.vue"
import EnumConfig from "@/enum/EnumConfig"

var CURR_VIEW_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `:S:${CURR_VIEW_VERSION}`;

export default {
    name: "DriverList",
    components: {
        EupPagination: EupPagination,
    },
    setup(){
     const state = reactive({
        pageIndex: 1,
        pageSize: 10,
        pageTotal:'',
         query: {
          feedbackType:'0',
           feedbackStatus:'0',
        },
        detailsVisible: false,//详情对话框显示
        editVisible:false,
        VIEW_VERSION: VIEW_VERSION,
        tableData:[],
        form:{
            createDate:"",
            description:"",
            feedbackStatus:"", 
            feedbackType:"", 
            picture:[],  
            userId:"",
            nickName:"",
            phone:"",
            emailL:""
        },
        editform:{
            createDate:"",
            description:"",
            feedbackStatus:"", 
            feedbackType:"", 
            picture:[],  
            userId:"",  
            feedbackId:"",
        },
        multipleSelection:[],
         loading: false,
         GetFeedbackTypeSelect: [
              { value: '0', label: '全部' },
              { value: '1', label: '建议' },
              { value: '2', label: '问题' },
            ],
         GetFeedbackStatusSelect: [
          { value: '0', label: '全部' },
          { value: '1', label: '未处理' },
          { value: '2', label: '已处理' },
            ],
         FeedbackTypeSelect: [
              { value: 1, label: '建议' },
              { value: 2, label: '问题' },
            ],
         FeedbackStatusSelect: [
          { value: 1, label: '未处理' },
          { value: 2, label: '已处理' },
            ],    
     });
     
     onMounted(() => {
         datasource()
    })

      const emailEmpty = (row) => {
         if (row.email=="") {
           return "未绑定"; 
         }else{
          return row.email
         }
    }
      const phoneEmpty = (row) => {
         if (row.phone=="") {
           return "未绑定"; 
         }else{
          return row.phone
         }
    }
     /**
     * @description 获取表单数据
     * @author lja
     */
    const datasource = () => {
      state.loading = true;
      let params={
        "currentPage": state.pageIndex,
        "pageSize": state.pageSize,
        "Filter.FeedbackType":state.query.feedbackType=='0'? null:state.query.feedbackType,
        "Filter.FeedbackStatus":state.query.feedbackStatus=='0'? null:state.query.feedbackStatus,
      }
     GetPageFeedback(params).then(function(res){
        if (res.code == 1) {
          state.pageTotal = res.data.total;
          state.tableData=res.data.list;
          state.multipleSelection= [];
        } else {
          ElMessage.error(res.msg);
        }
       state.loading = false;
      });
    }

     /**
     * @description 查询
     * @author lja
     */
    const handleSearch =()=>{
        state.pageIndex = 1
        datasource();
    }
      //详情
      const handleClickDetails = ((row) =>{
            state.detailsVisible  = true;
           let params = {
            feedbackId:row.feedbackId
            } 
          GetFeedback(params).then(res=>{
            if(res.code == 1)
            {
            state.form.createDate=res.data.createDate
            state.form.description=res.data.description
            state.form.userId=res.data.userId
            state.form.nickName=res.data.nickName
            state.form.phone=res.data.phone
            state.form.email=res.data.email
            state.form.feedbackStatus=res.data.feedbackStatus
            state.form.feedbackType=res.data.feedbackType
            state.form.picture= res.data.picture.split(",")
            }else{
               ElMessage.error(res.msg);
           }
          })
      })
         /**
         * @description 子组件返回分页数据
         * @author weig
         * @param {Object} obj
         */
        const resPageData = (obj) =>{
            state.pageIndex = obj.currPage;
            state.pageSize = obj.pageSize;
        }

        //是否显示处理按钮
        const isShow = ((row)=>{
          if (row.feedbackStatus == 1){
            return true;
          } else {
            return false;
          }
        });
        //处理
        const handleClickEdit = ((row) =>{
            state.editVisible  = true;
            let params = {
            feedbackId:row.feedbackId
            } 
          GetFeedback(params).then(res=>{
            if(res.code == 1)
            {
            state.editform.createDate=res.data.createDate
            state.editform.description=res.data.description
            state.editform.userId=res.data.userId
            state.editform.feedbackStatus=res.data.feedbackStatus
            state.editform.feedbackType=res.data.feedbackType
            state.editform.feedbackId=row.feedbackId
            state.editform.picture= res.data.picture.split(",")
            }else{
               ElMessage.error(res.msg);
           }
          })
      })
     /**
     * @description 批量删除
     * @author lja
     */
    const handleClickBatchDelete = () =>{
      if (state.multipleSelection.length == 0) {
        return ElMessage.error("请选择要删除的数据");
      }
      elConfirmDialog(
        ElMessageBox,
        "此操作将永久删除该数据, 是否继续?",
        "提示",
        "",
        () => {
          BatchDelete(state.multipleSelection)
            .then((res) => {
              if (res.code == 1) {
                ElMessage.success("删除成功");
                datasource();
              } else {
                ElMessage.error("删除失败!");
              }
            })
            .catch((err) => {
              ElMessage.error(err.msg);
            });
        },
        () => {
          ElMessage.info("取消删除!");
        }
      );
    }
    //批量勾选事件
    const handleSelectionChange = (val) => {
      state.multipleSelection = val.map((s) => {
        return s.feedbackId;
      })}
      //修改
      const DialogFormSave = ()=>{
          var picture=state.editform.picture.join(",")
         let params = {
            feedbackStatus:state.editform.feedbackStatus,
            feedbackId:state.editform.feedbackId,
            }
            Update(params).then(res =>{
                if(res.code == 1){
                    ElMessage.success("修改成功");
                    state.editVisible = false;
                    datasource();
                } else {
                    ElMessage.error(res.msg);
                }
            });

       }
       //关闭
      const closeEditForm = ()=>{
        state.editVisible = false;
        state.form={};
      };
        const closedetailsForm = ()=>{
        state.detailsVisible = false;
        state.form={};
      };

      /**
     * @description 删除
     * @author lja
     * @param {Object} row 行数据
     */
    const handleClickDelete = (row) =>{
       var id = row.feedbackId;
      elConfirmDialog(ElMessageBox,'此操作将永久删除该数据, 是否继续?','提示', '', ()=>{
        Delete(id).then(res =>{
          if(res.code == 1){
              ElMessage.success("删除成功!");
              datasource();
          } else {
              ElMessage.error(res.msg);
          }
        });
      },);
    }

    return {
    state,
    datasource,
    handleSearch,
    handleSelectionChange,
    handleClickBatchDelete,
    handleClickDetails,
    closeEditForm,
    closedetailsForm,
    handleClickDelete,
    DialogFormSave,
    handleClickEdit,
    resPageData,
    isShow,
    emailEmpty,
    phoneEmpty
    }
   }
    }
</script>
<style>
</style>
