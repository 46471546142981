/*
 * @Description: 
 * @Author: weig
 * @Date: 2021-09-01 20:26:53
 * @LastEditors: weig
 * @LastEditTime: 2021-12-17 11:35:11
 */
import request from '../../utils/request'
import EnumConfig from '../../enum/EnumConfig'

var CURR_API_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var API_VERSION = CURR_API_VERSION == 'V0' ? '' : `/S/${CURR_API_VERSION}`;

//修改
export const Update = (params)=>{
    return request.apiPut(`/api${API_VERSION}/System/Feedback/Update`,params);
}

//查询单条
export const GetFeedback = (params)=>{
    return request.apiGet(`/api${API_VERSION}/System/Feedback/Get`,params);
}

//查询分页
export const GetPageFeedback = (params)=>{
    return request.apiGet(`/api${API_VERSION}/System/Feedback/GetPage`,params);
}

//批量删除
export const Delete = (params)=>{
    return request.apiDelete(`/api${API_VERSION}/System/Feedback/Delete?feedbackId=${params}`);
}

//批量删除
export const BatchDelete = (params)=>{
    return request.apiPut(`/api${API_VERSION}/System/Feedback/BatchDelete`,params);
}